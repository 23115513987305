import { useForm } from "react-hook-form";

const useStatsFilters = ({ onFilterChange }) => {
  const form = useForm();

  const onSubmit = (formData) => {
    let queryString = `?filter=${formData.filter}`;

    if (formData.filter === "custom") {
      queryString += `&start_date=${formData.start_date}&end_date=${formData.end_date}`;
    }

    onFilterChange(queryString);
  };

  return {
    form,
    onSubmit,
  };
};

export default useStatsFilters;
