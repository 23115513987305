import { useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";
import { Flex, Button, Paper, Box, Center, Image } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useOutletContext } from "react-router-dom";
import DataTable from "../../../../common/components/DataTable/DataTable";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";
import MainLoader from "../../../../common/components/Loader/Loader";
import WithPermission from "../../../auth/components/WithPermission/WithPermission";
import { USER_TYPES } from "../../../../utils/userTypes";

const assignPlan = "issuance/assign-plan/";
const coversEndpoint = "issuance/covers-and-customize-covers/";
const removePlan = "issuance/user-cover-plan-access/remove/";

const AssignCover = () => {
  const outletContext = useOutletContext();
  const user = outletContext ? outletContext.user : null;
  const [value, toggle] = useToggle(["add", "view"]);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]); 

  const userEmail = user ? user.user_profile_info.user.email : null;
  const assignPlanUrl = userEmail
    ? `${assignPlan}?user_email=${userEmail}`
    : assignPlan;

  const {
    data: plans,
    error: plansError,
    isLoading: isPlansLoading,
    mutate: mutatePlans,
  } = useSWR(assignPlanUrl, fetcher);

  const {
    data: covers,
    error: coversError,
    isLoading: isCoversLoading,
  } = useSWR(plans ? coversEndpoint : null, fetcher, {
    dedupingInterval: 9000,
  });

  const tableConfig = {
    fields: [
      { key: "title", label: "Name" },
      { key: "cover_scope", label: "Scope" },
      { key: "currency", label: "Currency" },
      { key: "price", label: "Price" },
    ],
    selectionCheckbox: true,
    scrollable: true,
  };

  const handleSelection = (newSelection) => {
    setCurrentSelection(newSelection);
  };

  const handleAssignCover = async () => {
    if (!user) {
      return;
    }

    if (currentSelection.length === 0) {
      return errorNotifications("Please select covers");
    }

    try {
      const customizeCoverIds = currentSelection.filter(id =>
        covers.customize_covers.some(cover => cover.id === id)
      );

      const currentCustomize = Array.isArray(plans.customize_covers)
        ? plans.customize_covers.map(customize => customize.id)
        : [];

      const payload = {
        user_email: user.user_profile_info.user.email,
        customize_cover_ids: [...currentCustomize, ...customizeCoverIds],
      };

      await fetcher("issuance/assign-plan/", "POST", payload);
      await mutatePlans();
      toggle();
      successNotifications("Success, Covers assigned");
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  const handleRemovePlan = async () => {
    if (!user) return;

    if (selectedPlans.length === 0) {
      return errorNotifications("Please select plans to remove");
    }

    try {
      const payload = {
        user_id: user.user_profile_info.user.id,
        cover_ids: selectedPlans,
      };

      await fetcher(removePlan, "DELETE", payload);
      await mutatePlans();
      // toggle();
      successNotifications("Success, Plans removed");
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  if (isPlansLoading) {
    return <MainLoader />;
  }

  if (plansError) {
    return <p>Failed to load plans</p>;
  }

  const assignedCovers = Array.isArray(plans.customize_covers)
    ? plans.customize_covers
    : [];

  const combinedData = covers
    ? covers.customize_covers.filter(
        cover => !assignedCovers.some(assignedCover => assignedCover.id === cover.id)
      )
    : [];

  const EmptyComponent = () => (
    <Paper shadow="xl" style={{ backgroundColor: "#fff" }}>
      <Box style={{ textAlign: "center", padding: "20vh" }}>
        <Center>
          <Image
            src="/images/add-icon.png"
            width={80}
            height={114}
            alt="avatar"
          />
        </Center>
        <p>No covers available.</p>
        <WithPermission roleRequired={USER_TYPES.SUPERUSER}>
          <Button variant="outline" onClick={() => toggle()}>
            {value === "add" ? "+ Assign Covers" : "View Covers"}
          </Button>
        </WithPermission>
      </Box>
    </Paper>
  );

  return (
    <>
      <WithPermission roleRequired={USER_TYPES.SUPERUSER}>
        <Flex
          mih={50}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"lg"}
        >
          <Button variant="outline" onClick={() => toggle()}>
            {value === "add" ? "+ Assign Covers" : "View Covers"}
          </Button>
          <Button variant="outline" onClick={handleRemovePlan} disabled={selectedPlans.length === 0}>
            - Remove Plan
          </Button>
        </Flex>
      </WithPermission>

      {value === "add" ? (
        assignedCovers.length === 0 ? (
          <EmptyComponent />
        ) : (
          <DataTable
            config={tableConfig}
            data={assignedCovers}
            heading={"Assigned Covers"}
            onSelectionChange={setSelectedPlans}
          />
        )
      ) : (
        <DataTable
          config={{
            ...tableConfig,
            selectionActions: [{ label: "Save", handler: handleAssignCover }],
          }}
          data={coversError ? [] : combinedData}
          heading={"Select Covers"}
          loading={isCoversLoading}
          onSelectionChange={handleSelection}
        />
      )}
    </>
  );
};

export default AssignCover;
