import { useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Paper, Text, Button, TextInput, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import TravelerInfoForm from "../../forms/SingleIssuance/TravelerInfoForm";
import { TRAVELER_DOC } from "../../../../utils/issuance";
import nationalities from "../../../../data/nationality.json";
import { mapDataToOptions } from "../../../../utils/mapDataToOptions";
import { USER_TYPES } from "../../../../utils/userTypes";
import ConfirmModal from "../../../../common/components/ConfirmModal/ConfirmModal";

const TravelerInfo = ({
  data,
  coverType,
  handleTraveler,
  operator,
  isSubmitting,
}) => {
  const travelerRefs = useRef([]);
  const { user_type } = useSelector((state) => state.auth);
  const operatorProfile = useSelector((state) => state.operatorProfile);
  const [opened, { open, close }] = useDisclosure(false);

  const [allTravelerData, setAllTravelerData] = useState([]);
  const [tourOperatorName, setTourOperatorName] = useState(
    user_type[0] === USER_TYPES.OPERATOR ? operatorProfile.operator_name : ""
  );

  const [operatorPhoneNumber, setOperatorPhoneNumber] = useState(
    user_type[0] === USER_TYPES.OPERATOR
      ? operatorProfile.operator.phone_number
      : ""
  );

  const requireDocument = (
    nationality_id,
    traveller_from,
    traveller_to,
    residence_status = "no"
  ) => {
    const requiredDocuments = [];

    const getCountryName = (id) =>
      nationalities.find((country) => country.id === +id)?.name || null;

    const nationality = getCountryName(nationality_id);

    // Residence Proof
    if (
      nationality !== "Indian" &&
      residence_status &&
      residence_status === "yes"
    ) {
      requiredDocuments.push(TRAVELER_DOC.RESIDENCE_PROOF);
    }

    // Passport Front and Back
    if (
      nationality !== "Indian" ||
      traveller_from !== "India" ||
      traveller_to !== "India"
    ) {
      requiredDocuments.push(TRAVELER_DOC.PASSPORT_FRONT);
      requiredDocuments.push(TRAVELER_DOC.PASSPORT_BACK);
    }

    // KYC Document (Aadhar Card) and Flight Ticket
    if (
      (nationality === "Indian" &&
        traveller_from === "India" &&
        traveller_to !== "India") ||
      (nationality !== "Indian" &&
        residence_status &&
        residence_status === "yes" &&
        traveller_from === "India" &&
        traveller_to !== "India")
    ) {
      requiredDocuments.push(TRAVELER_DOC.AADHAR_FRONT);
      requiredDocuments.push(TRAVELER_DOC.AADHAR_BACK);
      requiredDocuments.push(TRAVELER_DOC.FLIGHT_TICKET);
    }

    return requiredDocuments;
  };

  const handleIndividualSubmit = (travelerData, index) => {
    const coverPricingId = data.covers[coverType][index];
    setAllTravelerData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...travelerData, cover_pricing_id: coverPricingId };
      return newData;
    });
  };

  const handleSubmit = () => {
    travelerRefs.current.forEach((formRef) => {
      if (formRef) {
        formRef.submit();
      }
    });

    open();
  };

  const handleConfirmSubmit = () => {
    if (
      allTravelerData.length === parseInt(data.quote_data.age_fields.length)
    ) {
      handleTraveler(
        allTravelerData,
        operatorProfile.operator.id,
        tourOperatorName,
        operatorPhoneNumber
      );
    }
    close();
  };

  return (
    <>
      {!operator && (
        <Paper p={"lg"} my={"lg"}>
          <Text fw={600} mb={"xl"}>
            Trip Details
          </Text>

          <SimpleGrid cols={{ base: 1, md: 2 }}>
            <TextInput
              label="Tour Operator Name"
              value={tourOperatorName}
              onChange={(e) => setTourOperatorName(e.target.value)}
              placeholder="Tour Operator Name"
              withAsterisk
            />
            <TextInput
              label="Operator Phone Number"
              value={operatorPhoneNumber}
              onChange={(e) => setOperatorPhoneNumber(e.target.value)}
              placeholder="Operator Phone Number"
              withAsterisk
            />
          </SimpleGrid>
        </Paper>
      )}

      {[...Array(parseInt(data.quote_data.age_fields.length))].map(
        (_, index) => (
          <Paper p={"lg"} my={"lg"} key={index}>
            <Text fw={600} mb={"xl"}>
              Traveler {index + 1}
            </Text>

            <TravelerInfoForm
              ref={(el) => (travelerRefs.current[index] = el)}
              travellerIndex={index}
              formData={data}
              options={mapDataToOptions(nationalities)}
              getRequiredDocs={requireDocument}
              onSubmit={handleIndividualSubmit}
            />
          </Paper>
        )
      )}

      <Button
        onClick={handleSubmit}
        loading={isSubmitting}
        loaderProps={{ type: "dots" }}
        type="submit"
      >
        Submit
      </Button>

      <ConfirmModal
        opened={opened}
        onClose={close}
        onConfirm={handleConfirmSubmit}
        title="Policy issuance confirmation"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        Are you sure you want issue policy ! Verify All details
      </ConfirmModal>
    </>
  );
};

export default TravelerInfo;
