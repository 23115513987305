import { useState, useCallback } from "react";
import { useFieldArray, Controller, useWatch } from "react-hook-form";
import {
  Button,
  Table,
  NumberInput,
  TextInput,
  Select,
  Paper,
  Group,
  Checkbox,
  ActionIcon,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { planTypes } from "../../../../../data/covers";
import ConfirmModal from "../../../../../common/components/ConfirmModal/ConfirmModal";

function BenefitsTable({
  plan,
  benefits,
  control,
  reset,
  onSubmit,
  mode,
  isSubmitting,
  handleOnDelete,
}) {
  const {
    fields: rowFields,
    append: appendRow,
    remove: removeRow,
    update: updateRow,
  } = useFieldArray({
    control,
    name: "rows",
  });

  const {
    fields: columnFields,
    append: appendColumn,
    remove: removeColumn,
  } = useFieldArray({
    control,
    name: "columns",
  });

  const isEditMode = mode === "edit";

  const watchedRows = useWatch({ control, name: "rows" });

  const [opened, { open, close }] = useDisclosure(false);

  const [rowIndexToDelete, setRowIndexToDelete] = useState(null);

  const findCoverageIndex = (coverages, coverType) => {
    return coverages?.findIndex((c) => c.cover_type === coverType);
  };

  const getAvailableOptions = (index) => {
    if (!benefits || benefits.length === 0) {
      return [];
    }

    const selectedBenefits = watchedRows
      .map((row, i) => (i === index ? null : row.benefit))
      .filter(Boolean);

    return benefits.filter(
      (benefit) => !selectedBenefits.includes(benefit.value)
    );
  };

  const handleRemoveColumn = (columnValue) => {
    const columnIndex = columnFields.findIndex(
      (col) => col.value === columnValue
    );
    removeColumn(columnIndex);
    watchedRows.forEach((row, rowIndex) => {
      const coverageIndex = row.coverages.findIndex(
        (c) => c.cover_type === columnValue
      );
      if (coverageIndex !== -1) {
        const updatedCoverages = [...row.coverages];
        updatedCoverages.splice(coverageIndex, 1);
        updateRow(rowIndex, { ...row, coverages: updatedCoverages });
      }
    });
  };

  const handleRemoveRowClick = (row) => {
    if (!row) {
      return;
    }

    setRowIndexToDelete(row.benefit_id);
    open();
  };

  const handleConfirmRemoveRow = () => {
    if (rowIndexToDelete !== null) {
      removeRow(rowIndexToDelete);
      handleOnDelete(rowIndexToDelete);
      close();
    }
  };

  const handleAddSingleBenefit = useCallback(() => {
    console.log("Adding one row"); // Debugging log to check if append is called multiple times
    appendRow({
      benefit: "", // Initialize benefit with an empty value
      coverages:
        columnFields.length > 0
          ? columnFields.map((col) => ({
              cover_type: col.value || "", // Initialize cover_type
              coverage_amount: "",
              max_coverage: "",
            }))
          : [], // Initialize coverages as an empty array if no columns exist
      deductible_amount: "", // Default deductible
      currency: "", // Default currency
    });
  }, [columnFields, appendRow]); // Ensure columnFields is in dependency so it's correctly updated

  return (
    <>
      <Paper py="lg" style={{ overflowX: "auto" }}>
        <form onSubmit={onSubmit}>
          <Table
            withRowBorders={false}
            verticalSpacing="md"
            style={{ minWidth: "1200px" }}
          >
            <Table.Thead>
              <Table.Tr
                style={{
                  borderRadius: "8px 0px 0px 0px",
                  background: "rgba(51, 51, 51, 0.18)",
                }}
              >
                {plan !== planTypes.MOTHER && (
                  <Table.Th style={{ width: "5%" }}>Select</Table.Th>
                )}
                <Table.Th style={{ width: "20%" }}>Benefit</Table.Th>
                {columnFields.map((column) => (
                  <Table.Th style={{ width: "20%" }} key={column.id}>
                    {column.value}
                    {isEditMode && columnFields.length > 0 && (
                      <ActionIcon
                        disabled={columnFields.length === 0}
                        onClick={() => handleRemoveColumn(column.value)}
                        variant="transparent"
                        aria-label="delete"
                      >
                        <img src="/images/svg/delete-icon.svg" alt="delete" />
                      </ActionIcon>
                    )}
                  </Table.Th>
                ))}
                <Table.Th style={{ width: "30%" }}>Deductible</Table.Th>
                <Table.Th style={{ width: "30%" }}>Currency</Table.Th>
                {plan === planTypes.MOTHER && <Table.Th>Actions</Table.Th>}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {rowFields.map((row, rowIndex) => (
                <Table.Tr key={row.id}>
                  {plan !== planTypes.MOTHER && (
                    <Table.Td>
                      <Controller
                        name={`rows.${rowIndex}.selected`}
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(event) =>
                              field.onChange(event.currentTarget.checked)
                            }
                            disabled={
                              !isEditMode && watchedRows[rowIndex]?.disabled
                            }
                          />
                        )}
                      />
                    </Table.Td>
                  )}

                  <Table.Td w={"20%"}>
                    <Controller
                      name={`rows.${rowIndex}.benefit`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          data={getAvailableOptions(rowIndex)}
                          value={field.value}
                          onChange={(value) => field.onChange(value)}
                          placeholder="Select Benefit"
                          searchable
                          disabled={
                            isEditMode || watchedRows[rowIndex]?.disabled
                          }
                        />
                      )}
                    />
                  </Table.Td>
                  {columnFields.map((column) => (
                    <Table.Td key={column.id}>
                      <Controller
                        name={`rows.${rowIndex}.coverages.${findCoverageIndex(
                          watchedRows[rowIndex]?.coverages,
                          column.value
                        )}.coverage_amount`}
                        control={control}
                        render={({ field }) => {
                          const coverage = watchedRows[
                            rowIndex
                          ]?.coverages?.find(
                            (c) => column.value === c.cover_type
                          );

                          return (
                            <NumberInput
                              {...field}
                              value={coverage?.coverage_amount || ""}
                              description={
                                plan !== planTypes.MOTHER
                                  ? `Max coverage ${
                                      coverage?.max_coverage || ""
                                    }`
                                  : ""
                              }
                              placeholder={`${column.value} Coverage `}
                              max={coverage?.max_coverage || undefined}
                              decimalScale={2}
                              allowNegative={false}
                              hideControls
                              disabled={
                                !isEditMode && watchedRows[rowIndex]?.disabled
                              }
                              style={{
                                marginBottom:
                                  plan !== planTypes.MOTHER ? "18px" : "0",
                              }}
                              onChange={(value) => {
                                field.onChange(value);
                              }}
                            />
                          );
                        }}
                      />
                    </Table.Td>
                  ))}

                  <Table.Td>
                    <Controller
                      name={`rows.${rowIndex}.deductible_amount`}
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          placeholder="deductible"
                          disabled={
                            !isEditMode && watchedRows[rowIndex]?.disabled
                          }
                        />
                      )}
                    />
                  </Table.Td>

                  {/* currency */}
                  <Table.Td w={"10%"}>
                    <Controller
                      name={`rows.${rowIndex}.currency`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          data={["USD", "INR", "NPR"]}
                          placeholder="INR, USD, NPR etc"
                          disabled={
                            !isEditMode && watchedRows[rowIndex]?.disabled
                          }
                        />
                      )}
                    />
                  </Table.Td>

                  {isEditMode && (
                    <Table.Td>
                      <Button
                        variant="outline2"
                        type="button"
                        onClick={() => handleRemoveRowClick(row)}
                        disabled={
                          !isEditMode && watchedRows[rowIndex]?.disabled
                        }
                      >
                        Remove
                      </Button>
                    </Table.Td>
                  )}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>

          {/* Actions Buttons */}
          <Group justify="center" gap="xl" mt="xl">
            {plan === planTypes.MOTHER && !isEditMode && (
              <Button
                type="button"
                variant="outline"
                onClick={handleAddSingleBenefit}
              >
                Add Row
              </Button>
            )}

            {plan === planTypes.MOTHER && (
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  const newColumnValue = `Option${columnFields.length + 1}`;
                  appendColumn({ value: newColumnValue });
                  rowFields.forEach((row, rowIndex) => {
                    const updatedCoverages = [
                      ...watchedRows[rowIndex].coverages,
                    ];
                    updatedCoverages.push({
                      cover_type: newColumnValue,
                      coverage_amount: "",
                      max_coverage: "",
                    });
                    updateRow(rowIndex, {
                      ...row,
                      coverages: updatedCoverages,
                    });
                  });
                }}
              >
                Add Column
              </Button>
            )}

            {plan === planTypes.MOTHER && (
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </Button>
            )}
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
            <Link to="edit">
              <Button variant="outline">Edit</Button>
            </Link>
            <Link to="/dashboard">
              <Button variant="outline2">Cancel</Button>
            </Link>
          </Group>
        </form>
      </Paper>

      {/* Confirm Modal */}
      <ConfirmModal
        isOpen={opened}
        title="Confirm Delete"
        content={`Are you sure you want to delete this benefit row?`}
        onCancel={close}
        onConfirm={handleConfirmRemoveRow}
      />
    </>
  );
}

export default BenefitsTable;
