import nationalities from "../../../data/nationality.json";

const genderOptions = [
  { label: "Male", value: "1" },
  { label: "Female", value: "2" },
  { label: "Other", value: "3" },
];

const coverTypeOptions = [
  { label: "Domestic", value: "Domestic" },
  { label: "International", value: "International" },
];

const purchaseMethodOptions = [
  { label: "Quote Link", value: "LINK" },
  { label: "Dashboard", value: "OFF" },
  { label: "Website", value: "online" },
];

export const filterOptions = [
  {
    label: "Cover Type",
    value: "cover_type",
    type: "checkbox",
    multiple: true,
    options: coverTypeOptions,
  },
  {
    label: "Purchase Method",
    value: "purchase_method",
    type: "checkbox",
    multiple: true,
    options: purchaseMethodOptions,
  },
  {
    label: "Gender",
    value: "gender",
    type: "checkbox",
    multiple: true,
    options: genderOptions,
  },
  {
    value: "date_range",
    label: "Date Range",
    type: "date-range",
    options: null,
  },
  {
    value: "nationality__name",
    label: "Nationality",
    type: "select",
    options: nationalities,
  },
];

export const sortOptions = [
  {
    label: "Sort By",
    value: "ordering",
    type: "checkbox",
    multiple: false,
    options: [
      { label: "Updated On", value: "updated_on" },
      { label: "Created On", value: "created_on" },
      { label: "Coverage Start Date", value: "coverage_start_date" },
      { label: "Coverage End Date", value: "coverage_end_date" },
      { label: "Total Amount", value: "total_amount" },
      { label: "No. of Pax", value: "no_of_pax" },
    ],
  },
];
