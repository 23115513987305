/**
 * Converts a date to YYYY-MM-DD or YYYY-MM-DD HH:mm:ss A format
 * @param {string|Date} timestamp - The date to format
 * @param {string} [type="date"] - The format type, either "date" or "datetime"
 * @returns {string} The formatted date string
 */
import dayjs from "dayjs";

export const FormatDate = (timestamp, type = "date") => {
  if (type === "datetime") {
    return dayjs(timestamp).format("YYYY-MM-DD hh:mm:ss A"); // 12-hour format with AM/PM
  }
  return dayjs(timestamp).format("YYYY-MM-DD"); // Default date-only format
};
