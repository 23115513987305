import ReactApexChart from "react-apexcharts";
import { useState } from "react";

const BarChart = ({ data }) => {
  const categories = data.map((item) => item.nationality || "Unknown");
  const seriesData = data.map((item) => item.percentage);

  const [series] = useState([
    {
      data: seriesData,
    },
  ]);

  const [options] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default BarChart;
