import Chart from "react-apexcharts";

const GroupBarChart = () => {
  const options = {
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: ["August", "September"],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Number of Travellers",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    tooltip: {
      y: {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          const seriesName = w.config.series[seriesIndex].name;
          const isDomestic = seriesName.includes("Domestic");
          const maleCount = isDomestic
            ? (series[seriesIndex][dataPointIndex] * 0.682).toFixed(0)
            : (series[seriesIndex][dataPointIndex] * 0.758).toFixed(0);
          const femaleCount = isDomestic
            ? (series[seriesIndex][dataPointIndex] * 0.318).toFixed(0)
            : (series[seriesIndex][dataPointIndex] * 0.242).toFixed(0);

          return ` ${value} travellers (Male: ${maleCount}, Female: ${femaleCount})`;
        },
      },
    },
    legend: {
      position: "top",
    },
  };

  const series = [
    {
      name: "Domestic",
      data: [22, 38], // Domestic policy counts for August and September
    },
    {
      name: "International",
      data: [0, 27], // International policy counts for August and September
    },
  ];

  return <Chart options={options} series={series} type="bar" height={400} />;
};

export default GroupBarChart;
