import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const ColumnChart = ({ internationalData, domesticData, xaxis, yaxis }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "International Trips",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Domestic Trips",
        data: [0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "45%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      xaxis: xaxis || {
        categories: ["Active", "Inactive", "Matured", "Pending", "Expired"],
        title: {
          text: "Status",
        },
      },
      yaxis: yaxis || {
        title: {
          text: "Policies",
        },
        labels: {
          formatter: (val) => val + " items",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " items";
          },
        },
      },
      colors: ["#00C2FF", "#FF8A00"],
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
    },
  });

  useEffect(() => {
    if (internationalData && domesticData) {
      setChartData((prevState) => ({
        ...prevState,
        series: [
          {
            name: "International Trips",
            data: [
              internationalData.active || 0,
              internationalData.inactive || 0,
              internationalData.matured || 0,
              internationalData.pending || 0,
              internationalData.expired || 0,
            ],
          },
          {
            name: "Domestic Trips",
            data: [
              domesticData.active || 0,
              domesticData.inactive || 0,
              domesticData.matured || 0,
              domesticData.pending || 0,
              domesticData.expired || 0,
            ],
          },
        ],
      }));
    }
  }, [internationalData, domesticData]);

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ColumnChart;
