import DataWrapper from "../../../covers/components/DataWrapper/DataWrapper";
import { filterOptions } from "../../utils/transaction";

const endpoint = "asc-wallet/master_wallet_transactions/";

const WalletTransaction = () => {
  const config = {
    fields: [
      { key: "amount", label: "Amount" },
      { key: "transaction_type", label: "Type" },
      { key: "transaction_number", label: "Number" },
      { key: "payment_mode", label: "Mode" },
      { key: "transaction_date", label: "Date" },
      { key: "wallet.full_name", label: "Name" },
      { key: "created_by", label: "Created By" },
      { key: "description", label: "Description" },
      { key: "status", label: "Status", type: "status" },
    ],
  };

  return (
    <>
      <DataWrapper
        endpoint={endpoint}
        config={config}
        filterOptions={filterOptions}
      />
    </>
  );
};

export default WalletTransaction;
