import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const formatCurrency = (value, currency) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  });
  return formatter.format(value);
};

const PieChart = ({ data }) => {
  const [chartData, setChartData] = useState({
    series: [0, 0, 0, 0],
    options: {
      chart: {
        type: "pie",
        width: 500, 
        height: 500,
      },
      labels: ["Gateway 1", "Gateway 2", "Gateway 3", "Gateway 4"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300, 
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
      },
      tooltip: {
        y: {
          formatter: (value, opts) => {
            const label = opts.config.labels[opts.seriesIndex];
            const currency = label === "HBL" ? "NPR" : "INR";

            return formatCurrency(value, currency);
          },
        },
      },
    },
  });

  useEffect(() => {
    if (data && data.gateway_totals) {
      const newSeries = data.gateway_totals.map((item) => {
        if (item.gateway === "HBL") {
          // Convert NPR to INR for HBL
          return item.total_amount_sum / 1.3;
        }
        return item.total_amount_sum;
      });
      const newLabels = data.gateway_totals.map((item) => item.gateway);

      setChartData((prevState) => ({
        ...prevState,
        series: newSeries,
        options: {
          ...prevState.options,
          labels: newLabels,
        },
      }));
    }
  }, [data]);

  return (
    <div
      id="chart"
      style={{
        display: "flex",
        justifyContent: "center", 
        alignItems: "center",
        height: "100%", 
      }}
    >
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width={500} 
        height={500} 
      />
    </div>
  );
};

export default PieChart;
