import { useCallback } from "react";
import DataWrapper from "../../covers/components/DataWrapper/DataWrapper";
import { successNotifications } from "../../../utils/handleNotifications";

const endpoint = "issuance/payments-list/";

const Payments = () => {
  const handleDownloadInvoice = useCallback(async (row) => {
    try {
      const { invoice_path: downloadUrl } = row;

      if (
        !downloadUrl ||
        !downloadUrl.startsWith("https://asc360.s3.amazonaws.com/")
      ) {
        successNotifications("Download URL is not available.");
        return;
      }

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.target = "_blank";
      link.rel = "noreferrer";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      successNotifications("Error downloading document:", error);
    }
  }, []);

  const handleTripRedirect = (row) => {
    return `/issuance/policies/trips/${row.trip}`;
  };

  const config = {
    fields: [
      { key: "transaction_id", label: "Transaction ID" },
      { key: "email", label: "Email" },
      { key: "payment_type", label: "Payment Type" },
      { key: "gateway", label: "Gateway" },
      { key: "method", label: "Method" },
      {
        key1: "currency",
        key2: "total_amount",
        label: "Total Amount",
        type: "combine",
      },
      { key: "created_on", label: "Date", type: "datetime" },
      { key: "invoice_number", label: "Invoice No." },
      { key: "transaction_status", label: "Status", type: "status" },
    ],
    handler: [
      {
        label: "Invoice",
        handler: (row) => handleDownloadInvoice(row),
      },
    ],
    actions: [
      {
        label: "Trip",
        to: (row) => handleTripRedirect(row),
        isButton: true,
      },
    ],
  };

  return (
    <>
      <DataWrapper endpoint={endpoint} config={config} />
    </>
  );
};

export default Payments;
