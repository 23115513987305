import { useState, useCallback } from "react";
import { FileButton, Button, Flex, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { fetcher } from "../../../../services/fetcher";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";
import ConfirmModal from "../../../../common/components/ConfirmModal/ConfirmModal";
import DataWrapper from "../../components/DataWrapper/DataWrapper";
import { filterOptions, sortOptions } from "../../utils/adventures";

const AdventuresPage = () => {
  const endpoint = "cover-api/adventures/";
  const uploadEndpoint = "cover-api/upload-adventures/";

  const [adventureCsv, setAdventureCsv] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);

  const handleEdit = (row) => {
    return `edit/${row.id}`;
  };

  const handleView = (row) => {
    return `${row.id}`;
  };

  const handleFileInput = async (file) => {
    setAdventureCsv(file);
    open();
  };

  const handleModalClose = () => {
    close();
    setAdventureCsv(null);
  };

  const handleImport = async () => {
    if (!adventureCsv) {
      return;
    }

    const formData = new FormData();
    formData.append("file", adventureCsv);

    try {
      await fetcher(uploadEndpoint, "POST", formData, {
        "content-type": "multipart/form-data",
      });

      successNotifications("Import successfully");
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    } finally {
      setAdventureCsv(null);
    }
  };

  const handleDelete = useCallback(async (row) => {
    const { id: adventure_id, name } = row;

    try {
      await fetcher(`${endpoint}${adventure_id}`, "DELETE");
      successNotifications(`${name} adventure deleted successfully`);
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  }, []);

  const config = {
    fields: [
      { key: "name", label: "Name" },
      { key: "category_name", label: "Category" },
      { key: "asc_level", label: "ASC Level" },
      { key: "created_by_name", label: "Created by" },
      { key: "create_date", label: "Date", type: "datetime" },
    ],
    actions: [
      { label: "View", to: (row) => handleView(row) },
      { label: "Edit", to: (row) => handleEdit(row) },
    ],
    handler: [
      {
        label: "Delete",
        handler: (row) => handleDelete(row),
      },
    ],
  };

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap"
        mb={"lg"}
      >
        <Link to={`add`}>
          <Button variant="outline">+ Add Adventure</Button>
        </Link>
        <FileButton onChange={handleFileInput} accept=".csv">
          {(props) => (
            <Button variant="outline2" {...props}>
              + Upload Adventure
            </Button>
          )}
        </FileButton>
      </Flex>

      <DataWrapper
        filterOptions={filterOptions}
        sortOptions={sortOptions}
        endpoint={endpoint}
        config={config}
      />

      <ConfirmModal
        opened={opened}
        onClose={handleModalClose}
        onConfirm={handleImport}
        title="Traveller Policy"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        Are you sure you want upload adventure csv{" "}
        {adventureCsv && (
          <Text size="sm" ta="center" mt="sm">
            Picked File: {adventureCsv.name}
          </Text>
        )}
      </ConfirmModal>
    </>
  );
};

export default AdventuresPage;
