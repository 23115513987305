import useSWR from "swr";
import { Link } from "react-router-dom";
import { fetcher } from "../../../../services/fetcher";
import { SimpleGrid, Button, Flex } from "@mantine/core";
import OperatorCard from "../../components/OperatorCard/OperatorCard";
import MainLoader from "../../../../common/components/Loader/Loader";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";

const endpoint = "operator/operator-profiles/";

const Operators = () => {
  const { isLoading, data: operators, error } = useSWR(endpoint, fetcher);

  const handleOperatorsExport = async () => {
    try {
      const response = await fetcher(`${endpoint}export-csv/`, "GET");

      if (response) {
        const csvBlob = new Blob([response], { type: "text/csv" });

        const url = window.URL.createObjectURL(csvBlob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = "operators.csv";

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Operators exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  if (isLoading) {
    return <MainLoader />;
  }

  if (error) {
    return <p>something went wrong</p>;
  }

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap"
        mb={"lg"}
      >
        <Link to={`add`}>
          <Button variant="outline">+ Create New Operator</Button>
        </Link>

        <Button variant="outline2" onClick={handleOperatorsExport}>
          Export All
        </Button>
      </Flex>

      <SimpleGrid
        cols={{ base: 1, xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }}
        spacing="lg"
      >
        {operators.map((operator) => {
          return <OperatorCard key={operator.id} data={operator} />;
        })}
      </SimpleGrid>
    </>
  );
};

export default Operators;
