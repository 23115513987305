import { useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";
import { SimpleGrid, Tabs } from "@mantine/core";
import CountStatsControls from "../../components/CountStatsControls/CountStatsControls";

const TripStats = () => {
  const [tripFilterQuery, setTripFilterQuery] = useState("?filter=today");
  const [policyFilterQuery, setPolicyFilterQuery] = useState("?filter=today");
  const [vendorFilterQuery, setVendorFilterQuery] = useState("?filter=today");

  const tripCount = `dashboard/trip-status${tripFilterQuery}`;
  const policiesCount = `dashboard/policy-count${policyFilterQuery}`;
  const vendorWisePolicy = `dashboard/vender-wise-policy${vendorFilterQuery}`;

  const { data: tripStats, isLoading: tripLoading } = useSWR(tripCount, fetcher);
  const { data: policiesStats, isLoading: policiesLoading } = useSWR(policiesCount, fetcher);
  const { data: vendorPoliciesStats, isLoading: vendorPoliciesLoading } = useSWR(vendorWisePolicy, fetcher);

  const tripStatsOptions = [
    { label: "Active", key: "active", icon: "/images/svg/active-policy.svg", color: "#00AA1C"  },
    { label: "Expired", key: "expired", icon: "/images/svg/expired-policy.svg", color: "#999"  },
    { label: "InActive", key: "inactive", icon: "/images/svg/inactive-policy.svg", color: "#ED0F01" },
    { label: "Matured", key: "matured", icon: "/images/svg/matured-policy.svg", color: "#1EC3FF" },
    { label: "Pending", key: "pending", icon: "/images/svg/pending-policy.svg", color: "#00AA1C" },
  ];

  const policyStatsOptions = [
    { label: "Travellers", key: "travellers_count", icon: "/images/svg/active-policy.svg", color: "#3498db" },
    { label: "Female", key: "female_policy_count", icon: "/images/svg/active-policy.svg", color: "#00AA1C"  },
    { label: "Male", key: "male_policy_count", icon: "/images/svg/inactive-policy.svg", color: "#ED0F01" },
    { label: "Domestic", key: "domestic_policy_count", icon: "/images/svg/matured-policy.svg", color: "#1EC3FF" },
    { label: "International", key: "international_policy_count", icon: "/images/svg/pending-policy.svg", color: "#00AA1C"  },
  ];

  const vendorPolicyStatsOptions = [
    { label: "ASC360", key: "ASC360", icon: "/images/svg/active-policy.svg", color: "#3498db" },
    { label: "ICICI", key: "ICICI", icon: "/images/svg/active-policy.svg", color: "#00AA1C" },
    { label: "Null", key: "null", icon: "/images/svg/inactive-policy.svg", color: "#ED0F01" },
  ];

  const filterOptions = [
    { value: "today", label: "Today" },
    { value: "week", label: "This Week" },
    { value: "month", label: "This Month" },
    { value: "year", label: "This Year" },
    { value: "custom", label: "Custom" },
  ];

  return (
    <div>
      <Tabs defaultValue="trips">
        <Tabs.List>
          <Tabs.Tab value="trips">Trips Counts</Tabs.Tab>
          <Tabs.Tab value="policies">Policy Counts</Tabs.Tab>
          <Tabs.Tab value="vendors">Vendor Policy Counts</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="trips">
          <SimpleGrid cols={{ base: 1, md: 1 }} spacing="md">
            <CountStatsControls
              data={tripStats}
              isLoading={tripLoading}
              onFilterChange={setTripFilterQuery}
              statsOptions={tripStatsOptions}
              filterOptions={filterOptions}
              statsTitle="Trips Counts"
              changeDayWise={true}
            />
          </SimpleGrid>
        </Tabs.Panel>

        <Tabs.Panel value="policies">
          <SimpleGrid cols={{ base: 1, md: 1 }} spacing="md">
            <CountStatsControls
              data={policiesStats}
              isLoading={policiesLoading}
              onFilterChange={setPolicyFilterQuery}
              statsOptions={policyStatsOptions}
              filterOptions={filterOptions}
              statsTitle="Policy Counts"
              changeDayWise={true}
            />
          </SimpleGrid>
        </Tabs.Panel>

        <Tabs.Panel value="vendors">
          <SimpleGrid cols={{ base: 1, md: 1 }} spacing="md">
            <CountStatsControls
              data={vendorPoliciesStats}
              isLoading={vendorPoliciesLoading}
              onFilterChange={setVendorFilterQuery}
              statsOptions={vendorPolicyStatsOptions}
              filterOptions={filterOptions}
              statsTitle="Vendor Policy Counts"
              changeDayWise={false}
            />
          </SimpleGrid>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default TripStats;
